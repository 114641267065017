<template>
  <application-sec />
</template>

<script>
import ApplicationSec from "../../components/solutions/security-solutions/ApplicationSec.vue";
export default {
  components: { ApplicationSec },
  name: "application-security-page",
};
</script>
